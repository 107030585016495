  import { BrowserRouter, Route, Routes } from "react-router-dom";
import AnimCurser from "./components/AnimCurser";
import SelfIntroPage from "./components/HomePage";
import ScrollUp from "./components/Scrollup";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SelfIntroPage />} />
      </Routes>
      <AnimCurser />
      <ScrollUp />
    </BrowserRouter>
  );}

export default App;
